/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../components/PrimaryButton';
import { BiPlusCircle } from 'react-icons/bi';
import ModalDelete from '../../components/ModalDelete';
import { useQueryClient } from 'react-query';
import PenugasanPenelitianTable from './components/PenugasanPenelitianTable';
import {
  useDeletePenugasanPenelitian,
  usePenugasanPenelitianByDosen,
  usePenugasanPenelitianByProdi,
  usePenugasanPenelitianData,
} from '../../hooks/usePenugasanPenelitian';
import useAuth from '../../hooks/useAuth';
import { useCheckRole } from '../../hooks/useCheckRole';
import PenugasanPenelitianTableDosen from './components/PenugasanPenelitianTableDosen';
import PenugasanPenelitianTableKaprodi from './components/PenugasanPenelitianTableKaprodi';

const PenugasanPenelitian = () => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const queryClient = useQueryClient();
  const userRole = useCheckRole();
  const {
    auth: { userData },
  } = useAuth();

  const {
    data: penugasanPenelitianData,
    isLoading: penugasanPenelitianDataIsLoading,
    refetch: penugasanPenelitianDataRefetch,
  } = usePenugasanPenelitianData({
    enabled: !!userRole.admin,
    select: (response) => {
      return response.data;
    },
  });

  const {
    data: penugasanPenelitianDataByDosen,
    isLoading: penugasanPenelitianDataByDosenIsLoading,
    refetch: penugasanPenelitianDataByDosenRefetch,
  } = usePenugasanPenelitianByDosen(userData.id, {
    enabled: !!userRole.facultyMember,
    select: (response) => {
      return response.data;
    },
  });

  const {
    data: penugasanPenelitianDataByProdi,
    isLoading: penugasanPenelitianDataByProdiIsLoading,
    refetch: penugasanPenelitianDataByProdiRefetch,
  } = usePenugasanPenelitianByProdi({
    enabled: !!userRole.kaprodi,
    select: (response) => {
      return response.data;
    },
  });

  const { mutate: deletePenugasanPenelitian } = useDeletePenugasanPenelitian();

  return (
    <section id="penelitian" className="section-container">
      <ModalDelete
        title="Penugasan Penelitian"
        isOpen={openModalDelete}
        setIsOpen={setOpenModalDelete}
        deleteFunc={() =>
          deletePenugasanPenelitian(selectedItem, {
            onSuccess: () => {
              if (userRole.admin) {
                penugasanPenelitianDataRefetch();
              } else if (userRole.dosen) {
                penugasanPenelitianDataByDosenRefetch();
              } else if (userRole.kaprodi) {
                penugasanPenelitianDataByProdiRefetch();
              }

              setOpenModalDelete(false);
            },
          })
        }
      />
      <div className="flex flex-col items-start lg:justify-between lg:items-center lg:flex-row space-y-2 lg:space-y-0">
        <p className="font-semibold text-lg">Daftar Penelitian</p>
        <PrimaryButton
          icon={<BiPlusCircle size={22} />}
          link="/pelaksanaan-penelitian/penugasan-penelitian/form"
        >
          Buat Penelitian
        </PrimaryButton>
      </div>
      <div className="mt-8 w-full rounded-t-lg">
        {userRole.admin && (
          <PenugasanPenelitianTable
            setSelectedItem={setSelectedItem}
            setOpenModalDelete={setOpenModalDelete}
            loading={penugasanPenelitianDataIsLoading}
            data={penugasanPenelitianData || []}
          />
        )}
        {userRole.kaprodi && (
          <PenugasanPenelitianTableKaprodi
            setSelectedItem={setSelectedItem}
            setOpenModalDelete={setOpenModalDelete}
            loading={penugasanPenelitianDataByProdiIsLoading}
            data={penugasanPenelitianDataByProdi || []}
          />
        )}
        {userRole.dosen && (
          <PenugasanPenelitianTableDosen
            setSelectedItem={setSelectedItem}
            setOpenModalDelete={setOpenModalDelete}
            loading={penugasanPenelitianDataByDosenIsLoading}
            data={penugasanPenelitianDataByDosen || []}
          />
        )}
      </div>
    </section>
  );
};

export default PenugasanPenelitian;
